
import { RouteNames } from '@/router/names'
import Vue, { PropType } from 'vue'
import { dispatchCanImproveWarehouse, dispatchImproveWarehouse } from '../store/actions'
import { ICompanyWarehouseModel } from '../types'
import { KwikDialog } from 'kwik-vue'

export default Vue.extend({
  name: 'FFCompanyWarehouseModelCard',
  components: { KwikDialog },
  props: {
    warehouseModel: {
      type: Object as PropType<ICompanyWarehouseModel>
    }
  },
  data () {
    return {
      canImprove: false,
      showImproveConfirm: false
    }
  },
  methods: {
    async goBatch () {
      await this.$router.push(
        {
          name: RouteNames.FF_BATCHES
        }
      )
    },
    async goDetail () {
      await this.$router.push(
        {
          name: RouteNames.FF_WAREHOUSE
        }
      )
    },
    async readCanImprove () {
      const canImprove = await dispatchCanImproveWarehouse(this.$store, this.warehouseModel.id)
      if (canImprove) {
        this.canImprove = canImprove.data
      } else {
        this.canImprove = false
      }
    },
    async startImprove () {
      this.showImproveConfirm = true
    },
    async improve () {
      await dispatchImproveWarehouse(this.$store, this.warehouseModel.id)
      await this.readCanImprove()
      this.$emit('improved')
      this.showImproveConfirm = false
    }
  },
  async mounted () {
    await this.readCanImprove()
  }
})
