
import Vue from 'vue'
import { KwikMediumContainer } from 'kwik-vue'
import { ICompanyWarehouseModel } from '../types'
import { dispatchGetCompanyWarehouseModels } from '../store/actions'
import FFCompanyWarehouseModelCard from '../components/FFCompanyWarehouseModelCard.vue'

export default Vue.extend({
  name: 'FFWarehouseModels',
  components: { KwikMediumContainer, FFCompanyWarehouseModelCard },
  data () {
    return {
      warehouseMPModels: [] as ICompanyWarehouseModel[],
      warehouseSLModels: [] as ICompanyWarehouseModel[],
      warehousePFModels: [] as ICompanyWarehouseModel[],
      loading: true,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Magazzini', disabled: false }
      ]
    }
  },
  methods: {
    async refresh () {
      const warehouseModels = await dispatchGetCompanyWarehouseModels(this.$store)
      this.warehouseMPModels = warehouseModels.filter((e: any) => e.warehouse_model.type === 'MP')
      this.warehouseSLModels = warehouseModels.filter((e: any) => e.warehouse_model.type === 'SL')
      this.warehousePFModels = warehouseModels.filter((e: any) => e.warehouse_model.type === 'PF')
    }
  },
  async mounted () {
    this.refresh()
  }
})
